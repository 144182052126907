.sd-private-policy {
}
.sd-print {
  background-color: #e6e8e9;
  padding: 60px;
  width: 100%;
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
  }
  .sd-print-inner {
    width: 100%;
    background-color: white;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white !important;

      h1 {
        font-size: 30px;
        color: #620404;

        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
    }
    span {
      font-weight: 700;
      padding-left: 3px;
      padding-right: 3px;
    }
    .sd-print-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        padding-bottom: 20px;
        width: 800px;
        border-bottom: 1px solid #bcbcbc;
        align-items: center;
        justify-content: center;

        label {
          white-space: nowrap;
          margin-right: 30px;
        }
        .sd-orange {
          background: #620404;
          padding: 4px 10px 8px;
          font-size: 14px;
          font-weight: 600;

          border-radius: 4px;
          font-size: 16px;
          color: #ffffff !important;
          font-weight: 400;
          margin-left: 30px;
          border: none;
          outline: none;
        }
        input {
          width: 40%;
        }
      }
    }
  }
  .sd-ticket-container {
    align-self: center;
    background-color: white;
    border-radius: 4px;
    width: 820px;
    border: 1px solid #bcbcbc;
    display: flex;
    flex-direction: column;
    .sd-d-top {
      width: 100%;
      position: relative;
      margin-top: 0px;
      img {
        margin-top: 0px;
        top: 0;
        position: absolute;
        width: 100%;
      }
    }
    .sd-d-bottom {
      display: flex;
      flex-direction: column;
      padding: 30px;
      padding-bottom: 0px;
      width: 100%;
      overflow: hidden;
      margin-top: 100px;
      .sd-d-bottom-main {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        // &:last-child {
        //   border-left: 1px solid red !important;
        // }
      }
      .sd-outer-p {
        text-align: center;
        margin: 40px 0px 20px 0px !important;
        color: #181818;
        font-size: 18px;
      }
      .sd-d-list {
        width: 100%;
        border: 2px solid rgb(172, 170, 170);
        border-radius: 6px;
        background-color: rgb(248, 248, 248);
        div {
          display: flex;
          border-bottom: 1px solid #e0dede;
          padding: 6px 20px;
          label {
            width: 35%;
            font-weight: 400;
            font-size: 14px;
            white-space: nowrap;
            padding-right: 5px;
            margin: 0px;
            line-height: 26px;
            text-transform: none;
          }
          h5 {
            width: 65%;
            text-transform: capitalize;
            white-space: wrap !important;
            margin: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
          }
        }
        div:last-child {
          border: none;
        }
      }
      .sd-d-terms {
        padding-top: 15px;
        p {
          font-weight: 600;
          color: #181818;
          margin-bottom: 5px;
          font-size: 16px;
        }
        .sd-d-terms-inner {
          padding: 5px;
          border: 2px solid rgb(172, 170, 170);
          border-radius: 6px;
          ol {
            list-style-type: decimal;
            li {
              // padding-left: 15px;
              margin-left: 20px;
              font-weight: 400;
              color: #181818;
              font-size: 14px;
            }
          }
        }
      }
      .sd-d-b-bottom {
        margin: 20px -30px 0px -30px;
        padding-left: -30px;

        .sd-d-d-one {
          background-color: #e6e6e6;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          .sd-d-d-one-left {
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            color: #181818;
            justify-content: space-between;
            text-align: right;
            p {
              font-weight: 500;
              color: #181818;
              font-size: 14px;
              margin-bottom: 0px;
              img {
                margin-left: 3px;
              }
            }
          }
          .sd-d-d-one-right {
            margin-top: 7px;
            p {
              padding-left: 20px;
              color: #181818;
              align-items: center;
              font-weight: 400;
              margin-bottom: 0px;
              font-size: 14px;
            }
          }
        }
        .sd-d-d-two {
          background-color: #620404;
          display: flex;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          justify-content: center;
          border-top: 5px solid #ecbd00;

          div {
            display: flex;
            padding: 6px 20px;
            align-items: center;
            text-align: center;

            img {
              height: 18px;
              padding-right: 3px;
            }
            a {
              color: white;
              text-align: center;
              font-weight: 500;
              margin-bottom: 0px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .sd-t-top {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0px;
        color: #181818;
      }
    }
    .sd-t-bottom {
      // margin-top: 30px;
      position: relative;
      display: flex;
      justify-content: space-between;
      .background {
        position: absolute;
        justify-content: center;
        top: 35%;
        left: 40%;
        opacity: 0.3;
      }
      .sd-border-right {
        border-right: 2px solid black;
        border-right-style: dashed;
      }

      .sd-t-container {
        opacity: 1 !important;
        width: 100%;
        .background {
          position: absolute;
          opacity: 0.3;
          height: 100px;
        }
        .left {
          left: 30%;
        }
        .right {
          left: 85%;
        }
        .sd-head-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            height: 55px;
            width: 50px;
            padding-bottom: 5px;
          }
          h4 {
            color: #181818;
            margin: 0px;
          }
        }
        .sd-t-body {
          width: 100%;
          padding-left: 2vw;
          display: flex;
          flex-direction: column;
          padding-top: 15px;

          .sd-t-content {
            width: 100%;
            div {
              display: flex;
              // align-items: center;
              label {
                width: 40%;
                font-weight: 600;
                font-size: 16px;
                white-space: nowrap;
                padding-right: 5px;
                margin: 0px;
                line-height: 26px;
              }
              h5 {
                width: 60%;
                text-transform: capitalize;
                white-space: wrap !important;
                margin: 0px;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
              }
            }
          }
          .sd-t-barcode {
            width: 80%;
            margin-top: 20px;
            align-self: center;
            height: 70px;

            svg {
              border: 1px solid #181818;
              height: 100%;
              border-radius: 4px;
              width: 100%;
            }
          }
        }
      }
      &::before {
        opacity: 0.3;

        background-image: url("../../Assets/srisaila.png");
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: lighten;
      }
    }
  }
  .bg-grey {
    background-color: #f3f3f3;
    border: none !important;
  }
}
.sd-t-and-c {
  background-color: #e6e8e9;
  padding: 60px;
  h2 {
    text-align: center;
  }
  .sd-t-and-c-inner {
    background-color: white;
    padding: 60px;
    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 30px;
        color: #a41503;
        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
    }
    span {
      font-weight: 700;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}
.p-0 {
  padding: 0px !important;
}
.sd-profile-popup {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;

  .inner {
    background-color: white;
    border: 1px solid #bcbcbc;
    border-top-color: #620404;
    border-top-width: 5px;
    animation: scale 0.7s;

    border-radius: 6px;
    width: 420px;
    min-height: 230px;
    // height: 400px;
    padding: 30px 30px;
    h2 {
      font-size: 18px;
      text-align: left;
      color: #620404;
      font-size: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #bcbcbc;
      position: relative;
      img {
        height: 12px;
        position: absolute;
        right: 0;
      }
      button {
        margin-left: 5px;
        a {
          font-size: 14px;
        }
      }
    }
    .inner-div {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .mobile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        height: 42px;
        position: relative;
        margin-bottom: 25px;
        .input-box {
          display: flex;
          align-items: center;
          max-width: 400px;
          border-radius: 4px;
          padding-left: 0.5rem;
          overflow: hidden;
          width: 100%;
          background-color: #eaeaea;
          &:hover > input {
            background-color: white;
          }
          &:focus-within {
            border: 1px solid #2f3193;
            background-color: white;
          }

          input {
            flex-grow: 1;
            font-size: 17px;
            border: none;
            outline: none;
            padding: 0.5rem;
            margin-bottom: 0px;
            background-color: #eaeaea;
            box-shadow: none;
            outline: none;
            &:focus {
              background-color: white;
              border: none !important;
            }
          }

          .voilet {
            border: 1px solid #2f3293;
            background-color: white !important;
            .PhoneInputCountry {
              background-color: white;
            }
            input {
              background-color: white;
            }
          }

          .prefix {
            display: flex;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 300;
            font-size: 17px;
            color: #999;
            &:hover + input {
              background-color: white !important;
            }
            img {
              padding-right: 10px;
            }
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }

        .input-box:hover {
          border: 1px solid #2f3293;
          background-color: white;
        }

        .input-box:focus {
          border: 1px solid #2f3293;
          background-color: white;
        }

        input:-internal-autofill-previewed {
          background-color: white;
        }
        .voilet {
          border: 1px solid #2f3293;
          background-color: white !important;
          .PhoneInputCountry {
            background-color: white;
          }
          input {
            background-color: white;
          }
        }
      }
      input {
        margin-bottom: 20px;
        height: 44px;
        border-radius: 4px;
        text-transform: none;
        padding: 15px;
        background-color: #f3f3f3;
        box-shadow: none;
        outline: none;
        &:focus {
          background-color: white;
          border: 1px solid #2f3193 !important;
        }
      }
      h2 {
        color: #181818;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        padding-bottom: 5px;
        text-align: left;
        border: none;
      }
      button {
        background: #620404;
        padding: 6px 10px 5px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff !important;
        border-radius: 4px;
        cursor: pointer;
        width: fit-content;
        border: none;
      }
      .sd-red {
        border: 1px solid red;
        background-color: white;
      }
      .sd-voilet {
        border: 1px solid #2f3193;
        background-color: white;
      }
      span {
        .left {
          cursor: pointer;
          margin-left: 3px;
          font-weight: 600;
        }
        .right {
          cursor: pointer;
          color: #620404;
          margin-left: 3px;
          font-weight: 600;
        }
      }
    }
  }
}
.sd-EM {
  position: absolute;
  bottom: -24px;
  font-size: 14px;
  right: 0;
  text-align: right;
  margin-bottom: 0;
  color: red;
}
.sd-otp-input-wrapper {
  label {
    color: #181818;
  }
  .sd-passcode-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    input {
      width: 50px !important;
      height: 50px !important;
      padding: 0 !important;
      margin-right: 5px !important;
      margin-bottom: 15px !important;
      text-align: center;
      border: 1px solid gray !important;
      border-radius: 5px !important;
      &:last-child {
        margin-right: 0;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
      &:focus,
      &.focus {
        border-color: green;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
@media screen and (min-width: 1826px) {
  .sd-t-and-c {
    margin-top: -10px !important;
  }
}
@media screen and (min-width: 650px) and (max-width: 978px) {
  .sd-print {
    background-color: #e6e8e9;
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    h2 {
      text-align: center;
    }
    .sd-print-inner {
      padding: 30px;
      .top {
        h1 {
          font-size: 30px;
          color: #620404;
          padding-bottom: 10px;
          letter-spacing: 0;
          font-weight: 400;
          span {
            font-weight: 600;
          }
        }
        img {
          height: 10px;
        }
      }
      span {
        font-weight: 700;
        padding-left: 3px;
        padding-right: 3px;
      }
      .sd-print-bottom {
        div {
          .sd-whitewrap {
            white-space: wrap !important;
          }
          label {
            white-space: nowrap;
            margin-right: 30px;
          }
          .sd-orange {
            background: #620404;
            padding: 4px 10px 8px;
            font-size: 14px;
            font-weight: 600;

            border-radius: 4px;
            font-size: 14px;
            color: #ffffff !important;
            font-weight: 400;
            margin-left: 15px;
            border: none;
            outline: none;
          }
          input {
            width: 40%;
          }
        }
      }
    }
    .sd-ticket-container {
      align-self: center;
      background-color: white;
      border-radius: 4px;
      width: 520px;
      border: 1px solid #bcbcbc;
      top: 0px;

      .sd-t-top {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;

        p {
          margin: 0px;
          color: #181818;
          font-size: 13px;
        }
      }
      .sd-t-bottom {
        // margin-top: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 60px !important;
        padding: 0px 30px;
        .background {
          position: absolute;
          justify-content: center;
          top: 35%;
          left: 40%;
          opacity: 0.3;
        }
        .sd-border-right {
          border-right: 2px solid black;
          border-right-style: dashed;
        }

        .sd-t-container {
          opacity: 1 !important;
          width: 100%;
          .background {
            position: absolute;
            opacity: 0.3;
            height: 100px;
          }
          .left {
            left: 30%;
          }
          .right {
            left: 85%;
          }
          .sd-head-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              height: 55px;
              width: 50px;
              padding-bottom: 5px;
            }
            h4 {
              color: #181818;
              margin: 0px;
            }
          }
          .sd-t-body {
            width: 100%;
            padding-left: 2vw;
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .sd-t-content {
              width: 100%;
              div {
                display: flex;
                // align-items: center;
                label {
                  width: 40%;
                  font-weight: 600;
                  font-size: 13px;
                  white-space: nowrap;
                  padding-right: 5px;
                  margin: 0px;
                  line-height: 20px;
                }
                h5 {
                  width: 60%;
                  text-transform: capitalize;
                  white-space: wrap !important;
                  margin: 0px;
                  font-weight: 400;
                  padding-left: 5px;
                  font-size: 13px;
                  line-height: 20px;
                }
              }
            }
            .sd-t-barcode {
              width: 80%;
              margin-top: 20px;
              align-self: center;
              height: 70px;

              svg {
                border: 1px solid #181818;
                height: 100%;
                border-radius: 4px;
                width: 100%;
              }
            }
          }
        }
        &::before {
          opacity: 0.3;

          background-image: url("../../Assets/srisaila.png");
          background-repeat: no-repeat;
          background-position: center;
          background-blend-mode: lighten;
        }
      }
      .sd-d-top {
        width: 100%;
        position: relative;

        img {
          margin-top: 0px;
          top: 0;
          position: absolute;
          width: 100%;
        }
      }
      .sd-d-bottom {
        display: flex;
        flex-direction: column;
        padding: 30px;
        padding-bottom: 0px;
        width: 100%;
        overflow: hidden;

        .sd-outer-p {
          text-align: center;
          margin: 20px 0px 20px 0px !important;
          color: #181818;
          font-size: 16px;
        }
        .sd-d-list {
          width: 100%;
          border: 2px solid rgb(172, 170, 170);
          border-radius: 6px;
          background-color: rgb(248, 248, 248);
          div {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #e0dede;
            padding: 4px 15px;
            label {
              width: 100%;
              font-weight: 600;
              font-size: 14px;
              white-space: pre-wrap;
              padding-right: 5px;
              margin: 0px;
              line-height: 20px;
              text-transform: none;
            }
            h5 {
              width: 100%;
              text-transform: capitalize;
              // white-space: wrap !important;
              margin: 0px;
              font-weight: 400;
              font-size: 14px;
              white-space: pre-wrap;
              line-height: 20px;
            }
          }
          div:last-child {
            border: none;
          }
        }
        .sd-d-terms {
          padding-top: 15px;
          p {
            font-weight: 600;
            color: #181818;
            margin-bottom: 5px;
            font-size: 14px;
          }
          .sd-d-terms-inner {
            padding: 5px;
            border: 2px solid rgb(172, 170, 170);
            border-radius: 6px;
            ol {
              list-style-type: decimal;
              li {
                // padding-left: 15px;
                margin-left: 20px;
                font-weight: 500;
                color: #181818;
                font-size: 13px;
              }
            }
          }
        }
        .sd-d-b-bottom {
          margin: 20px -30px 0px -30px;
          padding-left: -30px;

          .sd-d-d-one {
            background-color: #e6e6e6;
            padding: 10px;
            .sd-d-d-one-right {
              p {
                padding-left: 0;
                font-size: 13px;
              }
            }
            .sd-d-d-one-left {
              justify-content: space-around;
              p {
                padding-left: 0;
                font-size: 13px;
                white-space: nowrap;
                img {
                  height: 12px;
                }
              }
            }
          }
          .sd-d-d-two {
            background-color: #620404;
            display: flex;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            justify-content: center;
            border-top: 5px solid #ecbd00;

            div {
              display: flex;
              padding: 6px 10px;
              align-items: center;
              img {
                height: 18px;
                padding-right: 3px;
              }
              a {
                color: white;
                text-align: center;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 11px;
              }
            }
          }
        }
        .bg-grey {
          background-color: #e6e8e9;
        }
      }
    }
    .bg-grey {
      background-color: #f3f3f3;
      border: none !important;
    }
  }
}
@media screen and (min-width: 420px) and (max-width: 650px) {
  .sd-print {
    background-color: #e6e8e9;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    h2 {
      text-align: center;
    }
    .sd-print-inner {
      padding: 20px;
      .top {
        h1 {
          font-size: 30px;
          color: #620404;
          padding-bottom: 10px;
          letter-spacing: 0;
          font-weight: 400;
          span {
            font-weight: 600;
          }
        }
        img {
          height: 10px;
        }
      }
      span {
        font-weight: 700;
        padding-left: 3px;
        padding-right: 3px;
      }
      .sd-print-bottom {
        div {
          .sd-whitewrap {
            white-space: wrap !important;
          }
          label {
            white-space: nowrap;
            margin-right: 10px;
          }
          .sd-orange {
            background: #620404;
            padding: 2px 8px 5px;
            font-size: 14px;
            font-weight: 600;

            border-radius: 4px;
            font-size: 14px;
            color: #ffffff !important;
            font-weight: 400;
            margin-left: 15px;
            border: none;
            outline: none;
          }
          input {
            width: 40%;
            height: 90%;
          }
        }
      }
    }
    .sd-ticket-container {
      align-self: center;
      background-color: white;
      border-radius: 4px;
      width: 360px;
      border: 1px solid #bcbcbc;

      .sd-t-top {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        padding-top: 0px;
        top: 0px;
        p {
          margin: 0px;
          color: #181818;
          font-size: 13px;
        }
      }
      .sd-t-bottom {
        // margin-top: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        .background {
          position: absolute;
          justify-content: center;
          top: 35%;
          left: 10%;
          opacity: 0.3;
        }
        .sd-border-right {
          border-right: 2px solid black;
          border-right-style: dashed;
        }

        .sd-t-container {
          opacity: 1 !important;
          width: 100%;
          .background {
            position: absolute;
            opacity: 0.3;
            height: 100px;
          }
          .left {
            left: 10%;
          }
          .right {
            left: 65%;
          }
          .sd-head-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              height: 55px;
              width: 50px;
              padding-bottom: 5px;
            }
            h4 {
              color: #181818;
              margin: 0px;
              font-size: 14px;
              text-align: center;
            }
          }
          .sd-t-body {
            width: 100%;
            padding-left: 2vw;
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .sd-t-content {
              width: 100%;
              div {
                display: flex;
                // align-items: center;
                label {
                  width: 40%;
                  font-weight: 600;
                  font-size: 10px;
                  white-space: nowrap;
                  padding-right: 5px;
                  margin: 0px;
                  line-height: 15px;
                }
                h5 {
                  width: 60%;
                  text-transform: capitalize;
                  white-space: wrap !important;
                  margin: 0px;
                  font-weight: 400;
                  padding-left: 5px;
                  font-size: 10px;
                  line-height: 15px;
                }
              }
            }
            .sd-t-barcode {
              width: 80%;
              margin-top: 20px;
              align-self: center;
              height: 50px;

              svg {
                border: 1px solid #181818;
                height: 100%;
                border-radius: 4px;
                width: 100%;
              }
            }
          }
        }
        &::before {
          opacity: 0.3;

          background-image: url("../../Assets/srisaila.png");
          background-repeat: no-repeat;
          background-position: center;
          background-blend-mode: lighten;
        }
      }
      .sd-d-top {
        width: 100%;
        position: relative;

        img {
          margin-top: 0px;
          top: 0;
          position: absolute;
          width: 100%;
        }
      }
      .sd-d-bottom {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-bottom: 0px;
        width: 100%;
        overflow: hidden;

        .sd-outer-p {
          text-align: center;
          margin: 20px 0px 20px 0px !important;
          color: #181818;
          font-size: 14px;
        }
        .sd-d-list {
          width: 100%;
          border: 2px solid rgb(172, 170, 170);
          border-radius: 6px;
          background-color: rgb(248, 248, 248);
          div {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #e0dede;
            padding: 4px 15px;
            label {
              width: 100%;
              font-weight: 600;
              font-size: 13px;
              white-space: pre-wrap;
              padding-right: 5px;
              margin: 0px;
              line-height: 20px;
              text-transform: none;
            }
            h5 {
              width: 100%;
              text-transform: capitalize;
              white-space: wrap !important;
              margin: 0px;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
            }
          }
          div:last-child {
            border: none;
          }
        }
        .sd-d-terms {
          padding-top: 15px;
          p {
            font-weight: 600;
            color: #181818;
            margin-bottom: 5px;
            font-size: 14px;
          }
          .sd-d-terms-inner {
            padding: 5px;
            border: 2px solid rgb(172, 170, 170);
            border-radius: 6px;
            ol {
              list-style-type: decimal;
              li {
                // padding-left: 15px;
                margin-left: 20px;
                font-weight: 500;
                color: #181818;
                font-size: 12px;
              }
            }
          }
        }
        .sd-d-b-bottom {
          margin: 0px;
          padding-left: -30px;

          .sd-d-d-one {
            background-color: #e6e6e6;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sd-d-d-one-left {
              border-top: 1px solid #cbcbcb;
              padding: 0px;
              padding-top: 10px;

              p {
                font-weight: 500;
                color: #181818;
                font-size: 12px;
                margin-bottom: 0px;
                text-align: center;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                img {
                  margin-right: 5px;
                }
              }
            }
            .sd-d-d-one-right {
              padding-bottom: 10px;
              p {
                text-align: center;
                font-size: 12px;
              }
            }
          }
          .sd-d-d-two {
            background-color: #620404;
            display: flex;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            justify-content: center;
            border-top: 5px solid #ecbd00;
            flex-direction: column;
            padding-left: 20px;
            div {
              display: flex;
              padding: 6px 10px;
              align-items: center;
              text-align: center;

              img {
                height: 18px;
                padding-right: 3px;
              }
              a {
                color: white;
                text-align: center;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 11px;
              }
            }
          }
        }
        .bg-grey {
          background-color: #e6e8e9;
        }
      }
    }
    .bg-grey {
      background-color: #f3f3f3;
      border: none !important;
    }
  }
  .sd-t-and-c {
    background-color: #e6e8e9;
    padding: 30px;
    h2 {
      text-align: center;
      font-size: 18px;
    }
    .sd-t-and-c-inner {
      background-color: white;
      padding: 30px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 420px) {
  .sd-print {
    background-color: #e6e8e9;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    h2 {
      text-align: center;
    }
    .sd-print-inner {
      padding: 20px;
      .top {
        h1 {
          font-size: 30px;
          color: #620404;
          padding-bottom: 10px;
          letter-spacing: 0;
          font-weight: 400;
          span {
            font-weight: 600;
          }
        }
        img {
          height: 10px;
        }
      }
      span {
        font-weight: 700;
        padding-left: 3px;
        padding-right: 3px;
      }
      .sd-print-bottom {
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .sd-whitewrap {
            white-space: wrap !important;
          }
          label {
            white-space: nowrap;
            margin-right: 10px;
          }
          .sd-orange {
            background: #620404;
            padding: 2px 8px 2px;
            font-size: 12px;
            font-weight: 600;
            margin-top: 5px;

            border-radius: 4px;
            color: #ffffff !important;
            font-weight: 400;
            margin-left: 0px;
            border: none;
            outline: none;
          }
          input {
            width: 80%;
            height: 90%;
          }
        }
      }
    }
    .sd-ticket-container {
      align-self: center;
      background-color: white;
      border-radius: 4px;
      width: 260px;
      border: 1px solid #bcbcbc;

      .sd-t-top {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        padding-top: 0px;
        top: 0px;

        p {
          margin: 0px;
          color: #181818;
          font-size: 11px;
        }
      }
      .sd-t-bottom {
        // margin-top: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        .background {
          position: absolute;
          justify-content: center;
          top: 35%;
          left: 10%;
          opacity: 0.3;
        }
        .sd-border-right {
          border-right: 2px solid black;
          border-right-style: dashed;
        }

        .sd-t-container {
          opacity: 1 !important;
          width: 100%;
          .background {
            position: absolute;
            opacity: 0.3;
            height: 70px;
          }
          .left {
            left: 10%;
          }
          .right {
            left: 65%;
          }
          .sd-head-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              height: 35px;
              width: 30px;
              padding-bottom: 5px;
            }
            h4 {
              color: #181818;
              margin: 0px;
              font-size: 10px;
              text-align: center;
              white-space: nowrap;
            }
          }
          .sd-t-body {
            width: 100%;
            padding-left: 2vw;
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .sd-t-content {
              width: 100%;
              div {
                display: flex;
                // align-items: center;
                label {
                  width: 40%;
                  font-weight: 600;
                  font-size: 6px;
                  white-space: nowrap;
                  padding-right: 5px;
                  margin: 0px;
                  line-height: 10px;
                }
                h5 {
                  width: 60%;
                  text-transform: capitalize;
                  white-space: wrap !important;
                  margin: 0px;
                  font-weight: 400;
                  padding-left: 5px;
                  font-size: 6px;
                  line-height: 10px;
                }
              }
            }
            .sd-t-barcode {
              width: 80%;
              margin-top: 20px;
              align-self: center;
              height: 30px;

              svg {
                border: 1px solid #181818;
                height: 100%;
                border-radius: 4px;
                width: 100%;
              }
            }
          }
        }
        &::before {
          opacity: 0.3;

          background-image: url("../../Assets/srisaila.png");
          background-repeat: no-repeat;
          background-position: center;
          background-blend-mode: lighten;
        }
      }
      .sd-d-top {
        width: 100%;
        position: relative;

        img {
          margin-top: 0px;
          top: 0;
          position: absolute;
          width: 100%;
        }
      }
      .sd-d-bottom {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-bottom: 0px;
        width: 100%;
        overflow: hidden;

        .sd-outer-p {
          text-align: center;
          margin: 20px 0px 20px 0px !important;
          color: #181818;
          font-size: 12px;
          text-align: center;
        }
        .sd-d-list {
          width: 100%;
          border: 2px solid rgb(172, 170, 170);
          border-radius: 6px;
          background-color: rgb(248, 248, 248);
          div {
            display: flex;
            border-bottom: 1px solid #e0dede;
            padding: 2px 10px;
            label {
              width: 40%;
              font-weight: 600;
              font-size: 11px;
              white-space: pre-wrap;
              padding-right: 5px;
              margin: 0px;
              line-height: 20px;
              text-transform: none;
            }
            h5 {
              width: 60%;
              text-transform: capitalize;
              white-space: wrap !important;
              margin: 0px;
              font-weight: 400;
              font-size: 11px;
              line-height: 20px;
            }
          }
          div:last-child {
            border: none;
          }
        }
        .sd-d-terms {
          padding-top: 15px;
          p {
            font-weight: 600;
            color: #181818;
            margin-bottom: 5px;
            font-size: 14px;
          }
          .sd-d-terms-inner {
            padding: 5px;
            border: 2px solid rgb(172, 170, 170);
            border-radius: 6px;
            ol {
              list-style-type: decimal;
              li {
                // padding-left: 15px;
                margin-left: 20px;
                font-weight: 500;
                color: #181818;
                font-size: 10px;
              }
            }
          }
        }
        .sd-d-b-bottom {
          margin: 20px -30px 0px -30px;
          padding-left: -30px;

          .sd-d-d-one {
            background-color: #e6e6e6;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .sd-d-d-one-left {
              border-top: 1px solid #cbcbcb;
              padding: 0px;
              padding-top: 10px;

              p {
                font-weight: 500;
                color: #181818;
                font-size: 10px;
                margin-bottom: 0px;
                text-align: center;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                img {
                  margin-right: 5px;
                }
              }
            }
            .sd-d-d-one-right {
              p {
                text-align: center;
                font-size: 10px;
              }
            }
          }
          .sd-d-d-two {
            background-color: #620404;
            display: flex;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            justify-content: center;
            border-top: 5px solid #ecbd00;
            flex-direction: column;
            padding-left: 20px;
            div {
              display: flex;
              padding: 6px 10px;
              align-items: center;
              text-align: center;
              img {
                height: 18px;
                padding-right: 3px;
              }
              a {
                color: white;
                text-align: center;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 12px;
              }
            }
          }
        }
        .bg-grey {
          background-color: #e6e8e9;
        }
      }
    }
    .bg-grey {
      background-color: #f3f3f3;
      border: none !important;
    }
  }
  .sd-t-and-c {
    background-color: #e6e8e9;
    padding: 30px;
    h2 {
      text-align: center;
      font-size: 18px;
    }
    .sd-t-and-c-inner {
      background-color: white;
      padding: 30px;
      font-size: 14px;
    }
  }
}
@media print {
  .sd-ticket-container {
    align-self: start;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    padding: 0px !important;
    margin: 0px !important;

    .sd-d-top {
      // width: 100%;
      position: relative;

      img {
        position: absolute;
        width: 100% !important;
      }
    }
    .sd-d-bottom {
      display: flex;
      flex-direction: column;
      padding: 30px;
      padding-bottom: 0px;
      margin-top: 100px;
      // width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      .sd-d-bottom-main {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        // &:last-child {
        //   border-left: 1px solid red !important;
        // }
      }
      .sd-outer-p {
        text-align: center;
        margin: 15px 0px 20px 0px !important;
        color: #181818;
        font-size: 18px;
      }
      .sd-d-list {
        width: 100%;
        border: 2px solid rgb(172, 170, 170);
        border-radius: 6px;
        background-color: rgb(248, 248, 248);
        div {
          display: flex;
          border-bottom: 1px solid #e0dede;
          padding: 5px 20px;
          label {
            width: 37%;
            font-weight: 600;
            font-size: 14px;
            white-space: nowrap;
            padding-right: 5px;
            margin: 0px;
            line-height: 21px;
            text-transform: none;
          }
          h5 {
            width: 63%;
            text-transform: capitalize;
            white-space: wrap !important;
            margin: 0px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
          }
        }
        div:last-child {
          border: none;
        }
      }
      .sd-d-terms {
        // padding-top: 5px;
        width: 100%;

        p {
          font-weight: 600;
          color: #181818;
          margin-bottom: 5px;
          font-size: 14px;
        }
        .sd-d-terms-inner {
          padding: 5px;
          border: 2px solid rgb(172, 170, 170);
          border-radius: 6px;
          ol {
            list-style-type: decimal;
            li {
              // padding-left: 15px;
              margin-left: 20px;
              font-weight: 400;
              color: #181818;
              font-size: 14px;
            }
          }
        }
      }
      .sd-d-b-bottom {
        width: calc(100% + 60px);
        // margin: 20px -30px 0px -30px;
        margin-top: 10px;
        margin-left: -30px !important;

        .sd-d-d-one {
          background-color: #e6e6e6 !important;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          .sd-d-d-one-left {
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            color: #181818;
            justify-content: space-between;
            text-align: right;
            p {
              font-weight: 500;
              color: #181818;
              font-size: 14px;
              margin-bottom: 0px;
            }
          }
          .sd-d-d-one-right {
            margin-top: 7px;
            p {
              padding-left: 20px;
              color: #181818;
              align-items: center;
              font-weight: 400;
              margin-bottom: 0px;
              font-size: 14px;
            }
          }
        }
        .sd-d-d-two {
          background-color: #620404 !important;
          display: flex;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          justify-content: center;
          border-top: 5px solid #ecbd00;

          div {
            display: flex;
            padding: 6px 20px;
            align-items: center;
            color: white;

            img {
              height: 18px;
              padding-right: 3px;
            }
            a {
              color: white !important;
              text-align: center;
              font-weight: 500;
              margin-bottom: 0px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .sd-t-top {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0px;
        color: #181818;
      }
    }
    .sd-t-bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      .background {
        position: absolute;
        justify-content: center;
        top: 35%;
        left: 40%;
        opacity: 0.3;
      }
      .sd-border-right {
        border-right: 2px solid black;
        border-right-style: dashed;
      }

      .sd-t-container {
        opacity: 1 !important;
        width: 100%;
        .background {
          position: absolute;
          opacity: 0.3;
          height: 100px;
        }
        .left {
          left: 30%;
        }
        .right {
          left: 85%;
        }
        .sd-head-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            height: 55px;
            width: 50px;
            padding-bottom: 5px;
          }
          h4 {
            color: #181818;
            margin: 0px;
          }
        }
        .sd-t-body {
          width: 100%;
          padding-left: 2vw;
          display: flex;
          flex-direction: column;
          padding-top: 15px;

          .sd-t-content {
            width: 100%;
            div {
              display: flex;
              // align-items: center;
              label {
                width: 40%;
                font-weight: 600;
                font-size: 14px;
                white-space: nowrap;
                padding-right: 5px;
                margin: 0px;
                line-height: 26px;
              }
              h5 {
                width: 60%;
                text-transform: capitalize;
                white-space: wrap !important;
                margin: 0px;
                line-height: 26px;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
          .sd-t-barcode {
            width: 80%;
            margin-top: 20px;
            align-self: center;
            height: 70px;

            svg {
              border: 1px solid #181818;
              height: 100%;
              border-radius: 4px;
              width: 100%;
            }
          }
        }
      }
      &::before {
        opacity: 0.3;

        background-image: url("../../Assets/srisaila.png");
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: lighten;
      }
    }
    .bg-grey {
      background-color: #f3f3f3 !important;
      // border: none !important;
      height: fit-content;
    }
  }
}
@keyframes scale {
  0% {
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
