.w-100 {
  // width: 100%;
  font-family: "Proxima Nova Alt", sans-serif;
}
.box {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  .percent {
    position: relative;
    width: 150px;
    height: 140px;
    border-radius: 50%;
    background: transparent;
    z-index: 1000;
    svg {
      position: relative;
      width: 150px;
      height: 135px;
      transform: rotate(-95deg);
      circle {
        width: 100%;
        height: 100%;
        fill: none;
        // stroke: #2f3193;
        stroke-width: 10;
        stroke-linecap: round;
        transform: translate(5px, 5px);
        stroke-dasharray: 400;
      }
    }
    .number {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      h2 {
        color: #991403;
        font-weight: 700;
        font-size: 30px;
        transition: 0.5s;
      }
    }
  }
  .text {
    text-align: center;
    color: #181818;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.breadcrumb-div {
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 60px;
  border-bottom: 1px solid #d0d0d0;
  font-family: "Proxima Nova Alt", sans-serif;
  overflow-x: auto;
  border-bottom: 1px solid lightgrey;
  overflow-y: hidden;
  // position: sticky;
  // top: 125px;
  // z-index: 3000;
  .bc-left {
    display: flex;
    align-items: center;
    .mr-2 {
      margin-right: 1rem;
    }
    .ml-2 {
      margin-left: 0.5rem;
    }
    p {
      margin: 0px;
      font-size: 13px;
      letter-spacing: 0.2px;
      cursor: pointer;
      color: #181818;
      white-space: nowrap;
      text-transform: capitalize;
    }
    img {
      filter: invert(0.5);
      height: 10px;
    }
    .voilet {
      color: #a41503;
      font-weight: 500;
    }
  }
  .bc-right {
    display: flex;
    align-items: center;
    height: 60%;
    .gp-breadcrumb {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .gpbd-left {
        border-right: 1px solid #ccc;
        display: flex;
        justify-content: flex-end;
        h3 {
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          margin: 0 10px 0 0;
          color: #181818;
          white-space: nowrap;

          img {
            height: 15px;
            margin-right: 5px;
            // opacity: 0.5;
          }
        }
      }

      div {
        display: flex;
        padding: 0.5vw;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0px 18px 0px 17px;
          font-size: 17px;
          margin-right: 18px;
          color: #181818;
          white-space: nowrap;
          display: flex;
          align-items: baseline;

          font-weight: 600;
          img {
            height: 11px;
            margin-right: 3px;
          }
        }
        button {
          color: white;
          white-space: nowrap;

          background-color: #991403;
          border: none;
          display: flex;
          align-self: center;
          align-items: center;
          font-size: 14px;
          padding: 6px 13px;
          letter-spacing: 0.2px;
          justify-content: center;
          font-weight: 600;
          letter-spacing: 1px;
          img {
            height: 12px;
            margin: 2px 0 0 4px;
          }
        }
      }
    }
  }
}
.breadcrumb-div-about {
  background-color: #efefef;

  justify-content: flex-end;
  height: 50px;
  padding: 0 60px;
  border-bottom: 1px solid #d0d0d0;
  font-family: "Proxima Nova Alt", sans-serif;
  display: flex;

  align-items: center;
  border-bottom: 1px solid lightgrey;
  .sd-bc-left-about {
    display: flex;

    align-items: center;
    overflow-x: auto;

    width: 100%;
    .mr-2 {
      margin-right: 1rem;
    }
    .ml-2 {
      margin-left: 0.5rem;
    }
    p {
      margin: 0px;
      font-size: 13px;
      letter-spacing: 0.2px;
      cursor: pointer;
      color: #181818;
      white-space: nowrap;
    }
    img {
      filter: invert(0.5);
      height: 10px;
    }
    .voilet {
      color: #991403;
      font-weight: 500;
    }
  }
  .sd-bc-right-about {
    display: none;
    button {
      padding: 2px 10px;
      color: white;
      border: 1.5px solid #991403;
      background-color: #991403;
      outline: none;
      box-shadow: none;
      display: flex;
      align-items: center;
    }
    img {
      margin-left: 5px;
      height: 12px;
      transform: ease-in;
      color: #991403;
      filter: brightness(100);
    }
  }
}
.mr-2 {
  margin-right: 2rem;
}
.ml-2 {
  margin-left: 2rem;
}
.sd-coming-soon {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    color: #999999;
    font-size: 15px;
    margin-top: 10px !important;
  }
  p {
    font-size: 14px;
    margin-top: 10px;
    span {
      color: #991403;
      font-weight: 600;
    }
  }
}
.sd-loading {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5vw;

  img {
    // border: 2px solid orange;
    // border-radius: 50%;
    height: 70px;
  }
  p {
    color: #181818;
    color: #181818;
  }
}

@media screen and (min-width: 1500px) {
  .breadcrumb-div {
    .bc-left {
      p {
        font-size: 16px;
      }
    }
    .bc-right {
    }
  }
  .breadcrumb-div-about {
    .sd-bc-left-about {
      p {
        font-size: 16px;
      }
    }
    .sd-bc-right-about {
    }
  }
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .breadcrumb-div {
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;

    border-bottom: 2px solid lightgrey;
    .sd-bc-left-about {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        margin: 0px;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
      }
      img {
        filter: invert(0.5);
        height: 1vh;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
      .voilet {
        color: #991403;
        font-weight: 600;
      }
    }
    .bc-right {
      display: flex;
      align-items: center;
      height: 60%;
      width: 60%;
      .gp-breadcrumb {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        .gpbd-left {
          border-right: 2px solid #e5e5e5de;
          display: flex;
          justify-content: flex-end;
          h3 {
            display: flex;
            height: 100%;
            align-items: center;
            font-size: 10px;
            margin: 0px;
            margin-right: 5px;

            img {
              height: 12px;
              // margin-right: 5px;
            }
          }
        }

        div {
          display: flex;
          padding: 0.5vw;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0px;
            margin-right: 5px;

            font-size: 10px;
            font-weight: 600;
            i {
              font-size: 1vw;
              opacity: (0.8);
            }
            img {
              height: 9px;
              margin-right: 5px;
            }
          }
          button {
            white-space: nowrap;

            color: white;
            background-color: #991403;
            border: none;
            display: flex;
            align-self: center;
            align-items: center;
            font-size: 8px;
            padding: 6px 8px;
            justify-content: center;
            font-weight: 600;
            letter-spacing: 1px;
            white-space: nowrap;

            img {
              height: 11px;
            }
          }
        }
      }
    }
  }
  .breadcrumb-div-about {
    padding-left: 40px;
    padding-right: 40px;
    .sd-bc-left-about {
      p {
        margin: 0px;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
      }
      img {
        filter: invert(0.5);
        height: 1vh;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
      .voilet {
        color: #991403;
        font-weight: 600;
      }
    }
    .sd-bc-right-about {
    }
  }
}
@media screen and (max-width: 600px) {
  .breadcrumb-div {
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    border-bottom: 2px solid lightgrey;
    .bc-left {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        margin: 0px;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-align: center;
      }
      img {
        filter: invert(0.5);
        height: 1vh;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
      .voilet {
        color: #991403;
        font-weight: 600;
      }
    }
    .bc-right {
      display: flex;
      align-items: center;
      height: 60%;
      .gp-breadcrumb {
        display: none;
        width: 100%;
        height: 100%;

        align-items: center;
        .gpbd-left {
          width: 45%;
          border-right: 2px solid #e5e5e5de;
          display: flex;
          justify-content: flex-end;
          h3 {
            display: flex;
            height: 100%;
            align-items: center;
            font-size: 1.2vw;
            margin: 0px;
            margin-right: 1vw;

            i {
              margin-right: 0.3vw;
            }
          }
        }

        div {
          width: 55%;

          display: flex;
          padding: 0.5vw;
          justify-content: space-between;
          align-items: center;
          p {
            margin-left: 1vw;
            margin-bottom: 0px;
            font-size: 1.3vw;
            font-weight: 600;
            i {
              font-size: 1vw;
              opacity: (0.8);
            }
          }
          button {
            color: white;
            white-space: nowrap;

            background-color: #991403;
            border: none;
            display: flex;
            align-self: center;
            align-items: center;
            width: 50%;
            font-size: 1.15vw;
            padding: 0.4vw;
            justify-content: center;
            font-weight: 600;
            letter-spacing: 1px;
            white-space: nowrap;
            img {
              height: 9%;
              margin-top: 0.2vh;
              width: 15%;
            }
          }
        }
      }
    }
  }
  .breadcrumb-div-about {
    padding-left: 20px;
    padding-right: 20px;

    .sd-bc-left-about {
      // display: none;
      p {
        margin: 0px;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-align: center;
      }
      img {
        filter: invert(0.5);
        height: 1vh;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
      .voilet {
        color: #991403;
        font-weight: 600;
      }
    }
    .sd-bc-right-about {
      display: flex;

      button {
      }
      .sd-none {
        display: none !important;
      }
    }
  }
}
@media screen and (max-width: 378px) {
  .breadcrumb-div {
    padding-left: 20px;
    // width: 600px;
    overflow-x: scroll;
  }
  .breadcrumb-div-about {
    padding-left: 20px;
  }
}

@keyframes load {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: -1000;
  }
}
